import { Icon, Text, useTheme } from "@ui-kitten/components";
import { StyleSheet, View } from "react-native";

const styles = StyleSheet.create({
  example: {
    flexDirection: "row",
    alignItems: "center",
    width: "50%",
    marginVertical: 5,
  },
  example_icon: {
    width: 24,
    height: 24,
    marginRight: 10,
  },
});

const Example = ({
  good,
  bad,
  children,
}: {
  good?: boolean;
  bad?: boolean;
  children: string;
}) => {
  const theme = useTheme();

  return (bad && !good) || (good && !bad) ? (
    <View style={styles.example}>
      <Icon
        name={good ? "checkmark-circle-outline" : "alert-circle-outline"}
        fill={theme[`color-${good ? "success" : "danger"}-400`]}
        style={styles.example_icon}
      />
      <Text category="label">{children}</Text>
    </View>
  ) : null;
};

export default Example;
