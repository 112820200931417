import { useState } from "react";

import { Icon, Input, InputProps } from "@ui-kitten/components";
import { withTranslation } from "react-i18next";
import { TouchableWithoutFeedback } from "react-native";

type PasswordFieldProps = {
  password: string;
  setPassword: (password: string) => void;
  hidden?: boolean;
  t: (key: string) => string;
} & InputProps;

const PasswordField = ({
  password,
  setPassword,
  style,
  hidden,
  t,
  ...inputProps
}: PasswordFieldProps) => {
  const [secureTextEntry, setSecureTextEntry] = useState(true);

  const toggleSecureEntry = () => {
    setSecureTextEntry(!secureTextEntry);
  };

  const renderIcon = (props) => (
    <TouchableWithoutFeedback onPress={toggleSecureEntry}>
      <Icon {...props} name={secureTextEntry ? "eye-off" : "eye"} />
    </TouchableWithoutFeedback>
  );

  return (
    <Input
      value={password}
      label={t("password")}
      onChangeText={(nextValue) => setPassword(nextValue)}
      style={[hidden ? { display: "none" } : undefined, style]}
      accessoryRight={renderIcon}
      secureTextEntry={secureTextEntry}
      {...inputProps}
    />
  );
};

export default withTranslation("Login")(PasswordField);
