import { memo, ReactNode } from "react";

import { useCurrentPodcastIsNotNull } from "../../../stores/currentPodcast";

import PodcastNavigator from "./PodcastNavigator";
import { PodcastID } from "../../../stores/types/podcast";
import { ActivityIndicator, StyleSheet, View } from "react-native";
import usePodcast from "./hooks/usePodcast";
import { Text } from "@ui-kitten/components";

const styles = StyleSheet.create({
  loaderView: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
  },
});

const PodcastLoader = memo(
  ({
    podcastID,
    children,
  }: {
    podcastID: PodcastID["id"];
    children: ReactNode[];
  }) => {
    const { isLoading, isError } = usePodcast(podcastID);

    const currentPodcastIsNotNull = useCurrentPodcastIsNotNull();

    return isLoading ? (
      <View style={styles.loaderView}>
        <ActivityIndicator size="large" />
      </View>
    ) : currentPodcastIsNotNull ? (
      <>{children}</>
    ) : isError ? (
      <Text>An error occured</Text>
    ) : null;
  }
);

const Podcast = ({ route }) => {
  const podcastID = route?.params?.podcast_id;

  return (
    <PodcastLoader podcastID={podcastID}>
      <PodcastNavigator />
    </PodcastLoader>
  );
};

export default Podcast;
