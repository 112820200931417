import { useState } from "react";

import { Button, Input, Text } from "@ui-kitten/components";
import { withTranslation } from "react-i18next";

import styles from "../Login.styles";
import useLogin from "../hooks/useLogin";
import ErrorDisplay from "../../../atoms/ErrorDisplay";

import { EmailCheckResult } from "../hooks/useEmailChecker";

type OTPLoginProps = {
  t: (key: string) => string;
  email: string;
  tooManyOTPs: boolean;
  resendCode: Function;
  isSignUp: boolean;
};

const OTPLogin = ({
  t,
  email,
  tooManyOTPs,
  resendCode,
  isSignUp,
}: OTPLoginProps) => {
  const login = useLogin();

  const [otp, setOtp] = useState("");

  return (
    <>
      <Text category="s2">{t("otp.text")}</Text>
      {tooManyOTPs ? (
        <Text category="p1" status="warning" style={styles.formControl}>
          {t("otp.too_many")}
        </Text>
      ) : (
        <Button
          appearance="outline"
          size="tiny"
          onPress={(_e) => resendCode()}
          style={styles.formControl}
        >
          {t("otp.resend_code")}
        </Button>
      )}
      <Input
        value={otp}
        label={t("otp.label")}
        placeholder={t("otp.placeholder")}
        onChangeText={(nextValue) => setOtp(nextValue)}
        autoCorrect={false}
        keyboardType="default"
        style={styles.formControl}
        autoFocus
        returnKeyLabel={t(`buttons.${isSignUp ? "register" : "login"}`)}
        onSubmitEditing={() => login.mutate({ email, otp })}
      />
      <ErrorDisplay query={login} />
      {login.isSuccess ? (
        <Text category="s2">{t("loggedin")}</Text>
      ) : (
        <Button
          onPress={() => login.mutate({ email, otp })}
          style={styles.formControl}
        >
          {t(`buttons.${isSignUp ? "register" : "login"}`)}
        </Button>
      )}
    </>
  );
};

export default withTranslation("Login")(OTPLogin);
