import { StatusBar } from "expo-status-bar";

import { Layout, Text } from "@ui-kitten/components";

import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    padding: "4vw",
  },
});

export default () => {
  const podcast = {};

  return (
    <Layout level="2" style={styles.container}>
      <StatusBar style="auto" />
      <Text category="h3">Episodes (ID: {podcast.id})</Text>
    </Layout>
  );
};
