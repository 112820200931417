import { addTranslations } from "../../../../utils/i18n";

addTranslations("Dashboard", {
  welcome: {
    en: "Welcome to podCloud Studio !",
    fr: "Bienvenue sur le studio podCloud !",
  },
  before_your_first_episode: {
    en: "Before you publish your first episode, you have to create your podcast.",
    fr: "Avant de publier votre premier épisode, vous devez créer votre podcast.",
  },
  podcast_is_a_wrapper: {
    en: "It's the <1>wrapper</1> for your show, it's your show title, description and cover that will be shown on podCloud or any other podcasting platform. You can add unlimited episode inside of it.",
    fr: "C'est l'<1>emballage</1> de votre émission, c'est son titre, sa description et sa pochette qui s'afficheront sur podCloud et toutes les plateformes. Vous pourrez y ajouter autant d'épisodes que vous le souhaitez.",
  },
  create_my_podcast: {
    en: "Create my podcast",
    fr: "Créer mon podcast",
  },
  import_an_existing_podcast: {
    en: "Import an existing podcast...",
    fr: "Importer un podcast existant...",
  },
  publish_my_first_episode: {
    en: "Publish my first episode",
    fr: "Publier mon premier épisode",
  },
  broadcast_to_platforms: {
    en: "Broadcast to platforms",
    fr: "Diffuser sur les plateformes",
  },
});
