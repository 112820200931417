import { useCallback, useEffect, useState } from "react";

import { Button, Icon, Input, Text, useTheme } from "@ui-kitten/components";

import { useTranslation } from "react-i18next";

import "./Login.i18n";

import useEmailChecker from "./hooks/useEmailChecker";

import LoginLayout from "./components/LoginLayout";
import LoginTitle from "./components/LoginTitle";
import PasswordField from "./components/PasswordField";
import ErrorDisplay from "../../atoms/ErrorDisplay";
import PasswordLogin from "./components/PasswordLogin";
import OTPLogin from "./components/OTPLogin";

import styles from "./Login.styles";
import { TouchableWithoutFeedback } from "react-native";
import { detectedLang } from "../../../utils/i18n";

const Login = () => {
  const { t, i18n } = useTranslation("Login");
  const theme = useTheme();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const emailCheck = useEmailChecker();

  const checkEmail = useCallback(() => {
    emailCheck.mutate(
      { email, lang: i18n.language },
      {
        onSettled: (data, err) => {
          const lang = data
            ? data?.languages?.[0]
            : err?.response?.data?.languages?.[0];

          if (lang) {
            i18n.changeLanguage(lang);
          }
        },
      }
    );
  }, [email, i18n.language]);

  useEffect(() => {
    if (email !== emailCheck.variables?.email) {
      emailCheck.reset();
      i18n.changeLanguage(detectedLang);
    }
  }, [email, emailCheck.variables?.email]);

  const renderIcon = (props) => (
    <TouchableWithoutFeedback onPress={() => setEmail("")}>
      <Icon {...props} name={"close-circle-outline"} />
    </TouchableWithoutFeedback>
  );

  return (
    <LoginLayout {...{ t, theme }}>
      <LoginTitle isLogin={emailCheck.data?.isSignUp === false} />
      <Text category="s2" style={{ marginVertical: 10 }}>
        {t("email.intro")}
      </Text>
      <Input
        label={t("email.label") as string}
        placeholder={t("email.placeholder") as string}
        style={styles.formControl}
        keyboardType="email-address"
        caption={t("email.caption") as string}
        value={email}
        onChangeText={(nextValue) => setEmail(nextValue)}
        accessoryRight={email.length > 0 ? renderIcon : undefined}
        returnKeyLabel={t("continue")}
        onSubmitEditing={checkEmail}
        autoFocus
      />
      <PasswordField {...{ password, setPassword }} hidden />
      {emailCheck.isLoading ? (
        <Text>{t("email.checking")}</Text>
      ) : (
        <>
          <ErrorDisplay query={emailCheck} />
          {emailCheck.isSuccess ? (
            emailCheck.data?.hasPassword ? (
              <PasswordLogin {...{ email, password, setPassword }} />
            ) : (
              <OTPLogin {...emailCheck.data} resendCode={checkEmail} />
            )
          ) : (
            <Button onPress={checkEmail} style={styles.formControl}>
              {t("buttons.continue")}
            </Button>
          )}
        </>
      )}
    </LoginLayout>
  );
};

export default Login;
