import { Drawer, DrawerItem, IndexPath, useTheme } from "@ui-kitten/components";

import PodcastDrawerHeader from "./PodcastDrawerHeader";
import { usePodcastScreens } from "./screens";
import useCurrentRoute from "../../../hooks/useCurrentRoute";
import TransText from "../../../atoms/TransText";
import { DrawerActions } from "@react-navigation/native";

const PodcastDrawer = ({ navigation }) => {
  const currentRoute = useCurrentRoute();
  const items = usePodcastScreens(currentRoute.path);

  const theme = useTheme();

  return (
    <Drawer
      header={(props) => (
        <PodcastDrawerHeader
          hideDrawer={() => navigation.dispatch(DrawerActions.closeDrawer)}
          {...props}
        />
      )}
      selectedIndex={new IndexPath(items.selected?.row || 0)}
      onSelect={(index) => {
        const item = items.find((item) => item.row == index.row);

        if (item) navigation.jumpTo(item.name);
      }}
    >
      {items.map((item) => (
        <DrawerItem
          title={(props) => (
            <TransText
              {...props}
              style={[
                console.log(props.style),
                props.style,
                { color: theme["color-primary-100"] },
              ]}
            >{`Screens:${item.name}`}</TransText>
          )}
          key={item.row}
        />
      ))}
    </Drawer>
  );
};

export default PodcastDrawer;
