import { Layout, Text } from "@ui-kitten/components";
import { ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import useUserLoader from "./hooks/useUserLoader";

const UserLoader = ({ children }: { children: ReactNode }) => {
  const [isFirstTime, setIsFirstTime] = useState(true);

  const { t } = useTranslation();

  useEffect(() => setIsFirstTime(false), []);

  const { isLoading } = useUserLoader();

  return isFirstTime && isLoading ? (
    <Layout
      style={{
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <Text>{t("loading")}</Text>
    </Layout>
  ) : (
    <>{children}</>
  );
};

export default UserLoader;
