import { FC, forwardRef, useEffect, useMemo, useRef, useState } from "react";

import {
  Modal,
  Icon,
  Input,
  Text,
  useTheme,
  InputProps,
  ModalProps,
  Button,
} from "@ui-kitten/components";

import {
  NativeSyntheticEvent,
  StyleSheet,
  TextInputKeyPressEventData,
  useWindowDimensions,
  Pressable,
  View,
  Animated,
} from "react-native";

import { useHotkeys } from "react-hotkeys-hook";

import useUserPodcasts from "./hooks/useUserPodcasts";
import Cover from "../../../../../atoms/Cover";
import { useNavigation } from "@react-navigation/native";
import { addTranslations } from "../../../../../utils/i18n";
import { PodcastID } from "../../../../../stores/types/podcast";
import { useOmnibarVisibilityState } from "../../../../../stores/omnibar";

const modalStyles = StyleSheet.create({
  backdrop: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: "rgba(0,0,0,0.8)",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modal: {
    width: "80%",
    height: "100vh",
    justifyContent: "flex-start",
    overflow: "hidden",
    maxHeight: "100vh",
    paddingTop: "5vh",
  },
  modal_large: {
    paddingTop: "15vh",
  },
});

const AnimatedModal = Animated.createAnimatedComponent(Modal);

const SearchModal: FC<
  ModalProps & {
    onShowing?: () => void;
    onShown?: () => void;
    onHidding?: () => void;
    onHidden?: () => void;
  }
> = ({
  visible,
  backdropStyle,
  style,
  children,
  onShowing,
  onShown,
  onHidding,
  onHidden,
  ...props
}) => {
  const dimensions = useWindowDimensions();

  const isLargeScreen = dimensions.width >= 768;

  const [fadeAnim] = useState(new Animated.Value(visible ? 1 : 0));
  const [modalVisible, setModalVisible] = useState(visible);

  useEffect(() => {
    if (visible) {
      setModalVisible(true);
    } else {
      console.log("starting fadeout animation");
      onHidding?.();
      Animated.timing(fadeAnim, {
        toValue: 0,
        duration: 200,
        useNativeDriver: false,
      }).start(() => {
        onHidden?.();
        setModalVisible(false);
      });
    }
  }, [fadeAnim, visible]);

  useEffect(() => {
    if (visible && modalVisible) {
      console.log("starting fadein animation");
      onShowing?.();
      Animated.timing(fadeAnim, {
        toValue: 1,
        delay: 100,
        duration: 200,
        useNativeDriver: false,
      }).start(onShown);
    }
  }, [modalVisible, fadeAnim, onShowing, onShown]);

  const slideIn = fadeAnim.interpolate({
    inputRange: [0, 1],
    outputRange: ["100%", "0%"],
  });

  return (
    <AnimatedModal
      visible={modalVisible}
      style={[
        {
          position: "absolute",
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
        },
        { opacity: fadeAnim },
      ]}
      pointerEvents={"none"}
      {...props}
    >
      <Animated.View
        style={[modalStyles.backdrop, backdropStyle]}
        pointerEvents={visible ? "auto" : "none"}
      >
        <Animated.View
          style={[
            modalStyles.modal,
            isLargeScreen && modalStyles.modal_large,
            style,
            { transform: [{ translateY: slideIn }] },
          ]}
        >
          {children}
        </Animated.View>
      </Animated.View>
    </AnimatedModal>
  );
};

const searchInputStyles = StyleSheet.create({
  input: {
    width: "70vw",
    maxWidth: "600px",
    height: 20,
    fontSize: 15,
  },
  large: {
    height: 50,
    fontSize: 30,
  },
});

const useOmnibarTranslations = addTranslations("Omnibar", {
  placeholder: {
    en: "Search your podcasts...",
    fr: "Cherchez vos podcasts...",
  },
});

const SearchInput = ({ textStyle, ...props }: InputProps) => {
  const { t } = useOmnibarTranslations();
  const dimensions = useWindowDimensions();

  const isLargeScreen = dimensions.width >= 768;

  return (
    <Input
      placeholder={t("placeholder")}
      textStyle={[
        searchInputStyles.input,
        isLargeScreen && searchInputStyles.large,
        textStyle,
      ]}
      {...props}
    />
  );
};

const styles = StyleSheet.create({
  podcasts: {},
  podcast: {
    paddingVertical: 15,
    paddingHorizontal: 10,
    marginVertical: 5,
    borderRadius: 5,
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
  },
  cover: {
    width: 32,
    height: 32,
    marginRight: 10,
  },
});

export const Omnibar = () => {
  const navigation = useNavigation();
  const theme = useTheme();

  const [selected, setSelected] = useState(0);

  const [filter, setFilter] = useState("");

  const [visible, setVisible] = useOmnibarVisibilityState();

  const userPodcasts = useUserPodcasts(filter);

  const dimensions = useWindowDimensions();
  const maxItems = Math.floor((dimensions.height * (60 / 100)) / 65);

  const podcasts = useMemo(
    () => (userPodcasts.data || []).slice(0, maxItems),
    [userPodcasts.data, maxItems]
  );

  useEffect(
    () => (selected >= podcasts.length ? setSelected(0) : void 0),
    [selected, podcasts.length]
  );

  useHotkeys("ctrl+p", (ev) => {
    ev.preventDefault();
    setVisible(!visible);
  });

  useEffect(() => setFilter(""), [visible]);

  const selectPodcast = (podcast?: PodcastID) => {
    if (podcast && podcast.id) {
      console.log(`Selected podcast`, podcast);
      navigation.navigate("Podcast", { podcast_id: podcast.id });
    }
    setVisible(false);
  };

  const onKeyPress = (
    event: NativeSyntheticEvent<TextInputKeyPressEventData>
  ) => {
    const key =
      event.nativeEvent.key || (event.nativeEvent as KeyboardEvent)?.keyCode;

    const isEnter = key === "Enter" || key === 13;
    const isEscape = key === "Escape" || key == "Esc" || key === 27;
    const isUp = key === "ArrowUp" || key === 38;
    const isDown = key === "ArrowDown" || key === 40;

    if (
      (event.nativeEvent as KeyboardEvent)?.ctrlKey &&
      (key === "P" || key === "p" || key === 80)
    ) {
      setVisible(false);
      event.preventDefault();
    }

    if (isEnter || isEnter || isEscape || isUp || isDown) {
      event.preventDefault();
    }

    switch (true) {
      case isUp:
        setSelected(
          (selected) => (selected - 1 + podcasts.length) % podcasts.length
        );
        break;
      case isDown:
        setSelected((selected) => (selected + 1) % podcasts.length);
        break;
      case isEnter:
        selectPodcast(podcasts[selected]);
        setVisible(false);
        break;
      case isEscape:
        setVisible(false);
        break;
    }
  };

  useEffect(() => {
    if (podcasts.length < selected) setSelected(0);
  }, [selected, podcasts.length]);

  const [inputVisible, setInputVisible] = useState(visible);

  return (
    <SearchModal
      visible={visible}
      onBackdropPress={() => setVisible(false)}
      onHidden={() => setInputVisible(false)}
      onShowing={() => setInputVisible(true)}
    >
      <Button
        onPress={() => setVisible(false)}
        appearance="ghost"
        style={{ alignSelf: "flex-end", padding: 0, paddingHorizontal: 0 }}
        accessoryRight={(props) => (
          <Icon
            {...props}
            style={{ height: 26 }}
            fill="#fff"
            name="close-circle-outline"
          />
        )}
      >
        {(_evaProps) => <></>}
      </Button>
      {inputVisible ? (
        <SearchInput
          value={filter}
          onChangeText={setFilter}
          onSubmitEditing={console.log}
          onKeyPress={onKeyPress}
          autoFocus
        />
      ) : null}
      <View style={styles.podcasts}>
        {podcasts.slice(0, maxItems).map((podcast, index) => (
          <Pressable
            onHoverIn={() => setSelected(index)}
            onFocus={() => setSelected(index)}
            onPress={() => selectPodcast(podcast)}
            key={podcast.id}
          >
            <View
              style={[
                styles.podcast,
                selected == index && {
                  backgroundColor: theme["background-basic-color-1"],
                },
              ]}
            >
              <Cover source={podcast.cover.small} style={styles.cover} />
              <Text category="c1">{podcast.title}</Text>
            </View>
          </Pressable>
        ))}
      </View>
    </SearchModal>
  );
};

export default Omnibar;
