import {
  useRecoilValue,
  atom,
  useSetRecoilState,
  useRecoilState,
} from "recoil";

const omnibarVisibilityState = atom<boolean>({
  key: "omnibarVisibility",
  default: false,
});

export const useOmnibarVisibilityState = () =>
  useRecoilState(omnibarVisibilityState);

export const useShowOmnibar = () =>
  useSetRecoilState(omnibarVisibilityState).bind(undefined, true);
export const useHideOmnibar = () =>
  useSetRecoilState(omnibarVisibilityState).bind(undefined, false);
export const useOmnibarVisible = () => useRecoilValue(omnibarVisibilityState);

export default omnibarVisibilityState;
