import { Button, Text } from "@ui-kitten/components";
import { withTranslation } from "react-i18next";

import PasswordField from "./PasswordField";
import useLogin from "../hooks/useLogin";
import ErrorDisplay from "../../../atoms/ErrorDisplay";

import styles from "../Login.styles";

type PasswordLoginProps = {
  t: (key: string) => string;
  email: string;
  password: string;
  setPassword: (password: string) => void;
};

const PasswordLogin = ({
  t,
  email,
  password,
  setPassword,
}: PasswordLoginProps) => {
  const login = useLogin();

  return (
    <>
      <PasswordField
        {...{ password, setPassword }}
        autoFocus
        returnKeyLabel={t("button.login")}
        onSubmitEditing={() => login.mutate({ email, password })}
      />
      <ErrorDisplay query={login} />
      {login.isSuccess ? (
        <Text category="s2">{t("loggedin")}</Text>
      ) : (
        <Button
          onPress={() => login.mutate({ email, password })}
          style={styles.formControl}
        >
          {t("buttons.login")}
        </Button>
      )}
    </>
  );
};

export default withTranslation("Login")(PasswordLogin);
