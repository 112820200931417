import { StatusBar } from "expo-status-bar";

import { Button, Layout, Text } from "@ui-kitten/components";

import { useRecoilValue } from "recoil";

import { View, StyleSheet } from "react-native";
import Cover from "../../../../atoms/Cover";
import currentPodcastState from "../../../../stores/currentPodcast";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    padding: "4vw",
  },
  podcast_summary: {
    padding: 10,
    borderRadius: 5,
    flex: 1,
  },
  podcast_infos: {
    padding: 10,
    borderRadius: 5,
    flex: 1,
    height: 150,
    flexDirection: "row",
    alignItems: "center",
  },
  cover: { width: 100, height: 100, margin: 0, marginRight: 15 },
});

export default () => {
  const podcast = useRecoilValue(currentPodcastState);

  return podcast ? (
    <Layout level="2" style={styles.container}>
      <StatusBar style="auto" />
      <Layout level="3" style={styles.podcast_summary}>
        <Layout level="4" style={styles.podcast_infos}>
          <Cover source={podcast.cover.medium} style={styles.cover} />
          <View style={{ flex: 1, justifyContent: "center" }}>
            <Text category="h6">{podcast.title}</Text>
            <Text category="s1">{podcast.author}</Text>
          </View>
        </Layout>
        <View>
          <Button size="small">Modifier</Button>
        </View>
      </Layout>
      <Text category="h3">Summary (ID: {podcast.id})</Text>
    </Layout>
  ) : null;
};
