import { Image, ImageProps, StyleSheet } from "react-native";

const styles = StyleSheet.create({
  cover: {
    borderRadius: 6,
  },
});

export type CoverProps = ImageProps & {
  source: string | { uri: string } | { url: string };
};

const Cover = ({ source, style, ...props }: CoverProps) => (
  <Image
    source={{
      uri:
        typeof source === "string"
          ? source
          : "uri" in source
          ? source.uri
          : source.url,
    }}
    style={[styles.cover, style]}
    {...props}
  />
);

export default Cover;
