import { useRecoilValue, atom } from "recoil";
import Podcast from "./types/podcast";

const currentPodcastState = atom<Podcast | null>({
  key: "currentPodcastState",
  default: null,
});

// @method useCurrentPodcastIsNotNull
// @returns {boolean}
// @example
// import { useCurrentPodcastIsNotNull } from "../../../stores/currentPodcast";
//
// const currentPodcastIsNotNull = useCurrentPodcastIsNotNull();
//
export const useCurrentPodcastIsNotNull = () =>
  useRecoilValue(currentPodcastState) !== null;

// @method useCurrentPodcast
// @description
// Please note you should check hasCurrentPodcast() before using this
//
// @returns {Podcast}
// @example
// import { useCurrentPodcast } from "../../../stores/currentPodcast";
//
// const currentPodcast = useCurrentPodcast();
//
// if (currentPodcast) {
//   // do something
// }
//
// bang! means that we are sure that the podcast is not null
export const useCurrentPodcast = () => useRecoilValue(currentPodcastState)!;

export default currentPodcastState;
