import { addTranslations } from "../../../utils/i18n";
import WelcomeScreen from "./Welcome";

addTranslations("Screens", {
  Dashboard: {
    en: "Dashboard",
    fr: "Tableau de bord",
  },
});

export default () => {
  return <WelcomeScreen />;
};
