import { Text, TextProps } from "@ui-kitten/components";
import { AxiosError } from "axios";
import { withTranslation } from "react-i18next";
import { UseMutationResult } from "react-query";

import { addTranslations } from "../../utils/i18n";

addTranslations("ErrorDisplay", {
  invalid_email: {
    en: "Your email address is not valid.",
    fr: "Votre email n'est pas valide.",
  },
  invalid_password: {
    en: "Invalid password.",
    fr: "Mot de passe invalide.",
  },
  invalid_otp: {
    en: "This code is invalid.",
    fr: "Ce code est invalide.",
  },
  too_much_otp: {
    en: "You requested too many codes. Please try again later.",
    fr: "Vous avez demandé trop de codes. Réessayez plus tard.",
  },
  invalid_username: {
    en: "Username is invalid.",
    fr: "Le nom d'utilisateur est invalide.",
  },
  forbidden_username: {
    en: "Username is forbidden.",
    fr: "Le nom d'utilisateur est interdit.",
  },
  already_taken: {
    en: "Username is already taken.",
    fr: "Le nom d'utilisateur est déjà utilisé.",
  },
  unspecified: {
    en: "An error occured: {{err}}",
    fr: "Une erreur est survenue : {{err}}",
  },
});

type ErrorDisplayProps = {
  t: (key: string | string[], options: {}) => string;
  query: UseMutationResult<any, AxiosError, any, any>;
  showEmpty?: boolean;
} & TextProps;

const ErrorDisplay = ({
  query,
  t,
  style,
  showEmpty,
  ...textProps
}: ErrorDisplayProps) => {
  const ErrorText = ({ children }: TextProps) => (
    <Text
      status="danger"
      style={[{ fontWeight: "bold", marginVertical: 10 }, style]}
      {...textProps}
    >
      {children}
    </Text>
  );

  if (query.isError) {
    console.error("Error occured for query :", { query });

    return (
      <ErrorText>
        {(query.error?.response?.data?.errors || [query.error?.message])
          .map((err: string) => {
            console.error("Formating error: ", err);
            return t([err, `unspecified`], { err });
          })
          .join("")}
      </ErrorText>
    );
  } else if (showEmpty) {
    <ErrorText />;
  }

  return null;
};

export default withTranslation("ErrorDisplay")(ErrorDisplay);
