import { dark } from "@eva-design/eva";
import base from "./base";

export default {
  dark: true,
  ...dark,
  ...base,
  "color-primary-100": "#DBDCFC",
  "color-primary-200": "#B8B9FA",
  "color-primary-300": "#9293F2",
  "color-primary-400": "#7375E5",
  "color-primary-500": "#484AD5",
  "color-primary-600": "#3436B7",
  "color-primary-700": "#242599",
  "color-primary-800": "#16177B",
  "color-primary-900": "#0D0E66",
  "color-success-100": "#F3FCCD",
  "color-success-200": "#E4FA9D",
  "color-success-300": "#CDF06B",
  "color-success-400": "#B3E145",
  "color-success-500": "#8FCE10",
  "color-success-600": "#74B10B",
  "color-success-700": "#5C9408",
  "color-success-800": "#457705",
  "color-success-900": "#366203",
  "color-info-100": "#D3F9FF",
  "color-info-200": "#A9EFFF",
  "color-info-300": "#7EDFFF",
  "color-info-400": "#5DCDFF",
  "color-info-500": "#28B0FF",
  "color-info-600": "#1D89DB",
  "color-info-700": "#1467B7",
  "color-info-800": "#0C4993",
  "color-info-900": "#07347A",
  "color-warning-100": "#FEF8CE",
  "color-warning-200": "#FDEF9E",
  "color-warning-300": "#FBE36D",
  "color-warning-400": "#F8D648",
  "color-warning-500": "#F4C20E",
  "color-warning-600": "#D1A10A",
  "color-warning-700": "#AF8307",
  "color-warning-800": "#8D6604",
  "color-warning-900": "#755102",
  "color-danger-100": "#FFEBE0",
  "color-danger-200": "#FFD2C1",
  "color-danger-300": "#FFB4A3",
  "color-danger-400": "#FF978C",
  "color-danger-500": "#FF6866",
  "color-danger-600": "#DB4A55",
  "color-danger-700": "#B73348",
  "color-danger-800": "#93203C",
  "color-danger-900": "#7A1335",
};
