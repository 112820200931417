import { StatusBar } from "expo-status-bar";

import { Layout, Text } from "@ui-kitten/components";

import { useNavigation } from "@react-navigation/native";
import { Trans, useTranslation } from "react-i18next";

import "./Welcome.i18n";
import styles from "./Welcome.styles";

import Em from "../../../../atoms/Em";
import Step from "./WelcomeStep";

const Welcome = () => {
  const navigation = useNavigation();

  const { t } = useTranslation("Dashboard");

  return (
    <Layout style={styles.container}>
      <StatusBar style="auto" />
      <Text category="h3" style={styles.welcomeTitle}>
        {t("welcome")}
      </Text>
      <Layout style={styles.welcomeText}>
        <Text category="s1">{t("before_your_first_episode")}</Text>
        <Text category="p1" style={styles.welcomeS2}>
          <Trans i18nKey="Dashboard:podcast_is_a_wrapper">
            <Em />
          </Trans>
        </Text>
      </Layout>
      <Layout level="1">
        <Step
          number="1"
          disabled={false}
          onPress={() => navigation.push("Podcast", { podcast_id: 1 })}
        >
          {t("create_my_podcast")}
        </Step>
        <Text category="c1" style={styles.podcastAlreadyExists}>
          {t("import_an_existing_podcast")}
        </Text>
        <Step number="2" disabled={true}>
          {t("publish_my_first_episode")}
        </Step>
        <Step number="3" disabled={true}>
          {t("broadcast_to_platforms")}
        </Step>
      </Layout>
    </Layout>
  );
};

export default Welcome;
