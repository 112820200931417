import { Text, TextProps } from "@ui-kitten/components";
import { Trans, useTranslation } from "react-i18next";

type TransTextProps = { children: string } & TextProps;

const TransText = ({ children, ...props }: TransTextProps) => {
  const { t } = useTranslation();
  return <Text {...props}>{t(children.trim())}</Text>;
};

export default TransText;
