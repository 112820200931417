import axios from "../../../../../../utils/axios";

import { AxiosError } from "axios";
import { useMutation, useQueryClient } from "react-query";

import { User } from "../../../../../../stores/user";

const useUsernameClaim = () => {
  const queryClient = useQueryClient();

  const login = useMutation<
    User,
    AxiosError<{ errors: string[] }>,
    { username: string; save?: true }
  >(
    (variables) =>
      axios.put("/users/claim_username", variables).then((res) => res.data),
    {
      onSuccess: (_res, variables) => {
        if (variables.save) queryClient.refetchQueries(["SSO"]);
      },
    }
  );

  return login;
};

export default useUsernameClaim;
