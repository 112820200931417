import { Button, Divider, Icon, Layout, Text } from "@ui-kitten/components";
import { StyleSheet, ImageBackground, Image } from "react-native";

import { useCurrentPodcast } from "../../../stores/currentPodcast";

import { useShowOmnibar } from "../../../stores/omnibar";

const styles = StyleSheet.create({
  header: {
    height: 80,
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 8,
  },
  headerImage: {
    opacity: 0.4,
  },
  headerCover: {
    width: 50,
    height: 50,
  },
  podcastSwitcher: {
    paddingHorizontal: 0,
    paddingVertical: 0,
  },
  podcastTitle: {
    marginLeft: 12,
    marginRight: 10,
    textShadowColor: "rgba(0, 0, 0, 0.75)",
    textShadowOffset: { width: -1, height: 1 },
    textShadowRadius: 10,
    flexGrow: 1,
  },
  button: {
    margin: 0,
  },
});

const PodcastDrawerHeader = ({ style, hideDrawer }) => {
  const podcast = useCurrentPodcast();

  const showOmnibar = useShowOmnibar();

  return podcast ? (
    <Layout style={[style, styles.podcastSwitcher]}>
      <ImageBackground
        style={styles.header}
        imageStyle={styles.headerImage}
        source={{ uri: podcast.cover.big.url }}
        blurRadius={3}
      >
        <Image
          source={{ uri: podcast.cover.small.url }}
          style={styles.headerCover}
        />
        <Text category="s1" style={styles.podcastTitle} numberOfLines={3}>
          {podcast.title}
        </Text>
        <Button
          style={styles.button}
          appearance="ghost"
          size="small"
          status="control"
          accessoryRight={(props) => <Icon name="swap" {...props} />}
          onPress={() => {
            hideDrawer();
            showOmnibar();
          }}
        />
      </ImageBackground>
      <Divider />
    </Layout>
  ) : null;
};

export default PodcastDrawerHeader;
