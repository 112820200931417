import { FC, Suspense } from "react";

import { Text, Layout } from "@ui-kitten/components";
import { RecoilRoot } from "recoil";

import { useTranslation } from "react-i18next";

import { QueryClient, QueryClientProvider } from "react-query";

import ThemeProvider from "./src/theme/ThemeProvider";

import AppNavigation from "./src/AppNavigation/AppNavigation";

import { addTranslations } from "./src/utils/i18n";

addTranslations("App", {
  loading: {
    en: "Loading...",
    fr: "Chargement...",
  },
});

// Create a client
const queryClient = new QueryClient();

const App: FC = () => {
  const { t } = useTranslation();

  return (
    <RecoilRoot>
      <ThemeProvider>
        <QueryClientProvider client={queryClient}>
          <Suspense
            fallback={
              <Layout
                level="4"
                style={{
                  flex: 1,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Text>{t("loading")}</Text>
              </Layout>
            }
          >
            <AppNavigation />
          </Suspense>
        </QueryClientProvider>
      </ThemeProvider>
    </RecoilRoot>
  );
};

export default App;
