import { createDrawerNavigator } from "@react-navigation/drawer";

import PodcastDrawer from "./PodcastDrawer";
import { PODCAST_SCREENS } from "./screens";

const { Navigator, Screen } = createDrawerNavigator();

const PodcastNavigator = () => (
  <Navigator
    initialRouteName="Summary"
    screenOptions={{
      headerShown: false,
    }}
    drawerContent={(props) => <PodcastDrawer {...props} />}
  >
    {PODCAST_SCREENS.map((screen) => (
      <Screen
        name={screen.name}
        component={screen.component}
        key={screen.name}
      />
    ))}
  </Navigator>
);

export default PodcastNavigator;
