import { StyleSheet } from "react-native";
import { Layout } from "@ui-kitten/components";

import { useRegistrationState } from "../../../stores/user";

import "./CompleteRegistration.i18n";

import ClaimUsername from "./molecules/ClaimUsername";
import TOS from "./molecules/TOS";

const styles = StyleSheet.create({
  layout: {
    height: "100vh",
    alignItems: "center",
    justifyContent: "center",
  },
});

const CompleteRegistration = () => {
  const { guest, tos } = useRegistrationState();

  return (
    <Layout style={styles.layout}>
      {guest ? <ClaimUsername /> : tos ? <TOS /> : null}
    </Layout>
  );
};

export default CompleteRegistration;
