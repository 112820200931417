import axios from "../../../../../../utils/axios";

import { AxiosError } from "axios";
import { useMutation, useQueryClient } from "react-query";

const useTOSAgreement = () => {
  const queryClient = useQueryClient();

  const login = useMutation<void, AxiosError<{ errors: string[] }>>(
    () => axios.put("/users/agree_tos", {}).then((res) => res.data),
    {
      onSettled: (_res) => {
        queryClient.refetchQueries(["SSO"]);
      },
    }
  );

  return login;
};

export default useTOSAgreement;
