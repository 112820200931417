import { memo } from "react";

import LargeScreenHeader from "./LargeScreenHeader";
import MobileScreenHeader from "./MobileScreenHeader";

const Header = memo(({ isLargeScreen }: { isLargeScreen?: boolean }) =>
  isLargeScreen ? <LargeScreenHeader /> : <MobileScreenHeader />
);

export default Header;
