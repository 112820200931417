import {
  TopNavigation,
  TopNavigationAction,
  Icon,
} from "@ui-kitten/components";

import { DrawerActions, useNavigation } from "@react-navigation/native";
import { useTranslation } from "react-i18next";

import useCurrentRoute from "../../../../../hooks/useCurrentRoute";
import { useRecoilValue } from "recoil";
import podcastState from "../../../../../stores/currentPodcast";

const MobileScreenHeader = () => {
  console.log("Mobile Screen Header");

  const navigation = useNavigation();
  const currentRoute = useCurrentRoute();
  const podcast = useRecoilValue(podcastState);

  const { t } = useTranslation();

  return currentRoute ? (
    <TopNavigation
      accessoryLeft={
        podcast
          ? () => (
              <TopNavigationAction
                icon={(props) => <Icon name="menu-outline" {...props} />}
                onPress={() =>
                  navigation.dispatch(DrawerActions.toggleDrawer())
                }
              />
            )
          : undefined
      }
      title={t(`Screens:${currentRoute.name}`)}
    />
  ) : null;
};

export default MobileScreenHeader;
