import { useNavigationState } from "@react-navigation/native";

const makePath = (arr: string[]) => `/${arr.join("/").toLowerCase()}`;
const getCurrentRoute = (ns) => ns?.routes?.[ns?.index || 0];

const useCurrentRoute = () =>
  useNavigationState((ns) => {
    let currentRoutePath = [];

    let currentRoute = getCurrentRoute(ns);

    if (currentRoute?.name) {
      currentRoutePath.push(currentRoute.name);
      currentRoute.path = makePath(currentRoutePath);

      while (currentRoute?.state) {
        const subRoute = getCurrentRoute(currentRoute?.state);

        if (subRoute?.name) {
          currentRoutePath.push(subRoute.name);

          currentRoute = {
            ...subRoute,
            path: makePath(currentRoutePath),
            parent: currentRoute,
          };
        }
      }
    }

    return currentRoute;
  });

export default useCurrentRoute;
