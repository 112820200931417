import { useQuery, UseQueryResult } from "react-query";

import { PodcastID } from "./../../../../../../stores/types/podcast.d";
import axios from "../../../../../../utils/axios";

export type LastEpStats = {
  title: string;
  short_title: string;
  published_at: Date;
  listeners: number;
  downloads: number;
};

export type Last90daysStats = {
  downloads: number;
  listeners: number;
};

export type AllStats = {
  readonly lastep: UseQueryResult<LastEpStats | null>;
  readonly last90days: UseQueryResult<Last90daysStats>;
};

const query = (podcastID: PodcastID["id"], proj: keyof AllStats) => () =>
  useQuery(
    ["podcasts", podcastID, "stats", proj],
    () =>
      axios
        .get(
          "/studio/podcasts/" +
            encodeURIComponent(podcastID) +
            "/stats/" +
            proj,
          {
            params: {},
          }
        )
        .then((res) => res.data),
    { keepPreviousData: true }
  );

const useStats = (podcastID: PodcastID["id"]): AllStats =>
  Object.defineProperties(
    {} as AllStats,
    (["lastep", "last90days"] as (keyof AllStats)[]).reduce(
      (acc, prop) => ({
        ...acc,
        [prop]: { get: query(podcastID, prop) },
      }),
      {}
    )
  );

export default useStats;
