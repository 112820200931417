import User, { RegistrationState } from "./types/user";
import { PodcastID } from "./types/podcast";
import { atom, selector, useRecoilValue, useSetRecoilState } from "recoil";

const userState = atom<User>({
  key: "userState",
  default: null,
});

// export const useUserState = () => useRecoilState(userState);
// export const useGetUserState = () => useRecoilValue(userState);
export const useSetUserState = () => useSetRecoilState(userState);

export const isLoggedInState = selector<Boolean>({
  key: "isLoggedInState",
  get: ({ get }) => !!get(userState)?.id,
});

export const useLoggedInState = () => useRecoilValue(isLoggedInState);

export const emailState = selector<string | undefined>({
  key: "emailState",
  get: ({ get }) => get(userState)?.email,
});

export const useEmailState = () => useRecoilValue(emailState);

export const registrationState = selector<RegistrationState>({
  key: "registrationState",
  get: ({ get }) => {
    const user = get(userState);

    return {
      shouldCompleteRegistration: !!(user?.guest || user?.tos),
      guest: user?.guest || false,
      tos: user?.tos || null,
    };
  },
});

export const useRegistrationState = () => useRecoilValue(registrationState);

export const recentPodcastsState = selector<PodcastID[] | null>({
  key: "recentPodcastsState",
  get: ({ get }) => get(userState)?.recent_podcasts || null,
});

export const useRecentPodcastsState = () => useRecoilValue(recentPodcastsState);

export const podcastsCountState = selector<number>({
  key: "podcastsCountState",
  get: ({ get }) => get(userState)?.podcasts_count || 0,
});

export const usePodcastsCountState = () => useRecoilValue(podcastsCountState);

export default userState;
