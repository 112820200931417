import { addTranslations } from "../../../utils/i18n";

addTranslations("Login", {
  title: {
    registration: {
      en: "Registration",
      fr: "Inscription",
    },
    logination: {
      en: "Login",
      fr: "Connexion",
    },
  },
  email: {
    intro: {
      en: "Enter your email address to register or log in podCloud Studio.",
      fr: "Entrez votre email pour vous inscrire ou vous connecter sur le Studio podCloud.",
    },
    label: {
      en: "Email",
      fr: "Email",
    },
    placeholder: {
      en: "bob@myemail.com",
      fr: "michel@monemail.com",
    },
    caption: {
      en: "No ads, no newsletter. Your email will only be used for podCloud service messaging.",
      fr: "Pas de pub ou de newsletter. Votre email sera uniquement utilisé pour des messages de service podCloud.",
    },
    checking: {
      en: "Checking email...",
      fr: "Vérification...",
    },
  },
  password: {
    en: "Password",
    fr: "Mot de passe",
  },
  otp: {
    text: {
      en: "We've sent you an email with a one-time password to continue.",
      fr: "Nous vous avons envoyé un email avec un code à usage unique pour continuer.",
    },
    label: {
      en: "One Time Password",
      fr: "Code à usage unique",
    },
    placeholder: {
      en: "XXXXXX",
    },
    too_many: {
      en: "You requested too many codes. Use one you've already received or try again later.",
      fr: "Vous avez demandé trop de codes. Utilisez un de ceux que vous avez reçu ou réessayez plus tard.",
    },
    resend_code: {
      en: "Send another code",
      fr: "Envoyer un autre code",
    },
  },
  buttons: {
    continue: {
      en: "Continue",
      fr: "Continuer",
    },
    login: {
      en: "Login",
      fr: "Se connecter",
    },
    register: {
      en: "Register",
      fr: "S'inscrire",
    },
  },
  loggedin: {
    en: "Logged in !",
    fr: "Connecté !",
  },
});
