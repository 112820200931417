import { addTranslations } from "../../../../utils/i18n";

import Summary from "./Summary";
import Episodes from "./Episodes";
import Stats from "./Stats/Stats";

const PODCAST_SCREENS = [
  { name: "Summary", component: Summary, path: "/podcast/summary" },
  { name: "Episodes", component: Episodes, path: "/podcast/episodes" },
  { name: "Stats", component: Stats, path: "/podcast/stats" },
];

addTranslations("Screens", {
  Summary: {
    en: "Summary",
    fr: "Résumé",
  },
  Episodes: {
    en: "Episodes",
    fr: "Épisodes",
  },
  Stats: {
    en: "Statistics",
    fr: "Statistiques",
  },
});

type NavigationItem = {
  name: string;
  path: string;
  selected?: boolean;
  row: number;
};

type NavigationItems = NavigationItem[] & { selected?: NavigationItem };

const usePodcastScreens = (currentRoute?: string): NavigationItems => {
  const screens: NavigationItems = PODCAST_SCREENS.map((item, idx) => ({
    name: item.name,
    path: item.path,
    selected: item.path == currentRoute,
    row: idx,
  }));

  Object.defineProperty(screens, "selected", {
    get: function () {
      return this.filter((item: NavigationItem) => item.selected)?.[0];
    },
  });

  return screens;
};

export { PODCAST_SCREENS, usePodcastScreens };
