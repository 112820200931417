import { AxiosError } from "axios";

import { Platform } from "react-native";
import { useMutation } from "react-query";

import axios, { setToken } from "../../../../utils/axios";

import { User, useSetUserState } from "../../../../stores/user";

const useLogin = () => {
  const setUser = useSetUserState();

  const login = useMutation<
    User,
    AxiosError<{ errors: string[] }>,
    { email: string; password: string } | { email: string; otp: string }
  >(
    (data) =>
      axios
        .post("/users/me", data, {
          SSO: Platform.OS === "web",
        })
        .then((res) => res.data),
    {
      onSuccess: (data) => {
        setUser(data);
        setToken(data?.jwt || null);
      },
    }
  );

  return login;
};

export default useLogin;
