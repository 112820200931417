import { ReactNode } from "react";

import { Layout, useTheme } from "@ui-kitten/components";
import { Image, StyleSheet } from "react-native";

const styles = StyleSheet.create({
  layout: {
    flex: 1,
    paddingVertical: "10vh",
    paddingHorizontal: "12vw",
    alignItems: "center",
  },
  logoBanner: {
    width: "100%",
    maxWidth: 300,
    height: 100,
    marginBottom: "2vh",
  },
  form: {
    Width: "30%",
    maxWidth: 400,
    paddingHorizontal: 50,
    paddingVertical: 40,
    borderRadius: 10,
  },
});

const LoginLayout = ({ children }: { children: ReactNode }) => {
  const theme = useTheme();

  return (
    <Layout style={styles.layout}>
      <Image
        source={require(`../../../../assets/banner-transparent-${
          theme["dark"] ? "dark" : "light"
        }.png`)}
        style={styles.logoBanner}
        resizeMode="contain"
      />
      <Layout level="3" style={styles.form}>
        {children}
      </Layout>
    </Layout>
  );
};

export default LoginLayout;
