import { StyleSheet, View } from "react-native";

import {
  Icon,
  Layout,
  Button,
  Card,
  Text,
  useTheme,
} from "@ui-kitten/components";

import useTranslation from "./TOS.i18n";

import { useRegistrationState } from "../../../../../stores/user";
import useTOSAgreement from "./hooks/useTOSAgreement";
import ErrorDisplay from "../../../../atoms/ErrorDisplay";

const styles = StyleSheet.create({
  card: {
    maxWidth: 400,
    width: "90vw",
  },
  first_time: {
    marginVertical: 10,
  },
  intro: {
    marginBottom: 20,
  },
  outro: {
    marginVertical: 20,
  },
  changes: { padding: 15, marginVertical: 10 },
  change: {
    flexDirection: "row",
    justifyItems: "flex-start",
  },
  changeArrow: { height: 25, marginRight: 5 },
});

const TOS = () => {
  const { t } = useTranslation();

  const { tos } = useRegistrationState();

  const TOSAgreement = useTOSAgreement();

  const theme = useTheme();

  return (
    <Card
      style={[
        styles.card,
        { backgroundColor: theme["background-basic-color-3"] },
      ]}
      status="basic"
      appearance="filled"
      header={(props) => (
        <View {...props}>
          <Text category="h6">{t("our_tos")}</Text>
        </View>
      )}
      footer={(props) => (
        <View {...props}>
          <Button status="primary" onPress={() => TOSAgreement.mutate()}>
            {t("accept")}
          </Button>
        </View>
      )}
    >
      {tos!.accepted_at === null ? (
        <>
          <Text style={styles.first_time} category="s1">
            {t("first_time")}
          </Text>
          <ErrorDisplay query={TOSAgreement} />
        </>
      ) : (
        <>
          <Text style={styles.intro} category="p1">
            {t("changed.intro", {
              updated_at: tos!.updated_at,
            })}
          </Text>
          <Layout level="2" style={styles.changes}>
            {tos!.changes.map((change) => (
              <View style={styles.change}>
                <Icon
                  name="arrow-right"
                  fill={"#ddd"}
                  style={styles.changeArrow}
                />
                <Text>{change}</Text>
              </View>
            ))}
          </Layout>
          <Text style={styles.outro} category="p2">
            {t("changed.outro")}
          </Text>
          <ErrorDisplay query={TOSAgreement} />
        </>
      )}
    </Card>
  );
};

export default TOS;
