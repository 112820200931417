import * as React from "react";
import * as eva from "@eva-design/eva";
import { ApplicationProvider, IconRegistry } from "@ui-kitten/components";
import { EvaIconsPack } from "@ui-kitten/eva-icons";

import dark from "./dark";

const ThemeProvider: React.FC<Props> = ({ children }) => (
  <>
    <IconRegistry icons={EvaIconsPack} />
    <ApplicationProvider {...eva} theme={dark}>
      {children}
    </ApplicationProvider>
  </>
);

export default ThemeProvider;
