import i18next, { Resource as I18nResource } from "i18next";
import { initReactI18next, useTranslation } from "react-i18next";
import { getLocales } from "react-native-localize";
import set from "lodash/set";

import {
  format as formatDate,
  formatDistance,
  formatRelative,
  isDate,
} from "date-fns";
import { enUS, fr } from "date-fns/locale";

const dateLocales = { enUS, fr }; // used to look up the required locale

export const detectedLang = getLocales()[0]?.languageCode;
const defaultLng = "en";

i18next.use(initReactI18next).init({
  resources: {},
  lng: detectedLang,
  fallbackLng: defaultLng,
  fallbackNS: "App",
  defaultNS: "App",
  interpolation: {
    escapeValue: false,
    format: (value, format, lng = defaultLng) => {
      if (isDate(value)) {
        const locale = dateLocales[lng == "en" ? "enUS" : lng];

        if (format === "short") return formatDate(value, "P", { locale });
        if (format === "long") return formatDate(value, "PPPP", { locale });
        if (format === "relative")
          return formatRelative(value, new Date(), { locale });
        if (format === "ago")
          return formatDistance(value, new Date(), {
            locale,
            addSuffix: true,
          });

        return formatDate(value, format as string, { locale });
      }

      return value;
    },
  },
});

type TranslationLeaf = { [languageCode: string]: string };
type TranslationNode = { [key: string]: TranslationNode | TranslationLeaf };
type TranslationTree = TranslationNode;

type UseTranslationHook = () => {
  t: (key: string | string[], variables?: object) => string;
};

export const addTranslations = (
  bundle: string,
  translations: TranslationTree
): UseTranslationHook => {
  const bundles: I18nResource = {};

  const traverseTree = (
    tree: TranslationNode | TranslationLeaf,
    resources: I18nResource,
    currentPath?: string
  ) => {
    Object.keys(tree).forEach((key) => {
      const node = tree[key];

      if (typeof node === "string") {
        set(resources, `${key}.${currentPath}`, node);
      } else if (typeof node === "object") {
        traverseTree(
          node,
          resources,
          currentPath ? `${currentPath}.${key}` : key
        );
      }
    });
  };

  traverseTree(translations, bundles);

  Object.keys(bundles).forEach((languageCode) => {
    i18next.addResourceBundle(languageCode, bundle, bundles[languageCode]);
  });

  return () => useTranslation(bundle);
};

export default i18next;
