import { addTranslations } from "../../../../../utils/i18n";

export default addTranslations("ClaimUsername", {
  our_tos: {
    en: "Our terms and conditions",
    fr: "Nos conditions générales d'utilisations",
  },
  first_time: {
    en: `Before using our platform you must fully read and accept our terms and services.`,
    fr: `Avant d'utiliser notre plateforme, vous devez avoir lu et accepter nos conditions générales d'utilisation et de vente.`,
  },
  changed: {
    intro: {
      en:
        "Our terms and conditions have been updated the {{updated_at, short}}." +
        "\n\n" +
        "Here is a summary of these changes :",
      fr:
        "Nos conditions générales d'utilisation et de vente ont été mises à jour le {{updated_at, short}}." +
        "\n\n" +
        "Voici un résumé de ces mises à jour :",
    },
    outro: {
      en:
        "To continue using our services you have to read and accept the full terms and conditions." +
        "\n\n" +
        "If you want more informations, you can contact us by email or via our contact page.",
      fr:
        "Pour continuer à utiliser nos services, vous devez avoir lu et accepté l'intégralité de ces conditions générales d'utilisation et de vente." +
        "\n\n" +
        "Si vous souhaitez des informations complémentaires ou refuser ces nouvelles conditions et supprimer votre compte, vous pouvez nous contacter par mail ou via notre page contact.",
    },
  },
  accept: {
    en: "I read and accept",
    fr: "J'ai lu et j'accepte",
  },
});
