import { Text } from "@ui-kitten/components";

import { withTranslation } from "react-i18next";
import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  title: {
    textAlign: "center",
    marginTop: -15,
    marginBottom: 15,
  },
});

type LoginTitleProps = { isLogin: boolean; t: Function };

const LoginTitle = ({ isLogin, t }: LoginTitleProps) => (
  <Text category="h6" style={styles.title}>
    {t(`title.${isLogin ? "logination" : "registration"}`)}
  </Text>
);

export default withTranslation("Login")(LoginTitle);
