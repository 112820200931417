import { addTranslations } from "../../../../../utils/i18n";

export default addTranslations("ClaimUsername", {
  complete_registration: {
    en: "Complete registration",
    fr: "Terminer mon inscription",
  },
  one_moment: {
    en: "One moment please!",
    fr: "Une petite minute !",
  },
  before_access: {
    en: "Before you can access your dashboard, we need to finish your registration.",
    fr: "Avant d'accéder à votre tableau de bord, nous devons finir votre inscription.",
  },
  choose_your_username: {
    en: "You have to choose a personal username.\n\nIt will be used for your person, on your podcaster profile page, or when you share playlists or recommand a podcast.",
    fr: "Vous devez choisir votre nom d'utilisateur·ice personnel.\n\nIl sera utilisé pour vous même, sur votre profil podcasteur·euse, ou quand vous partagez une playlist ou recommandez un podcast.",
  },
  username: {
    en: "Username",
    fr: "Nom d'utilisateur",
  },
  username_chars: {
    en: "Letters, numbers, and underscores (_) only.",
    fr: "Lettres, nombres, et tirets bas (_) uniquement.",
  },
  not_your_podcast_name: {
    en: "It should not be your podcast name : you will be able to create multiple podcasts with your account.",
    fr: "Cela ne doit pas être le nom de votre podcast : vous pourrez créer plusieurs podcasts avec votre compte.",
  },
  some_examples: {
    title: {
      en: "Some examples",
      fr: "Quelques exemples",
    },
    good1: {
      en: "john_smith",
      fr: "michel_dupont",
    },
    good2: {
      en: "thejohn",
      fr: "michel3000",
    },
    bad1: {
      en: "the_john_show",
      fr: "que_la_montagne",
    },
    bad2: {
      en: "john_media_group",
      fr: "michel_prods",
    },
  },
});
