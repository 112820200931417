import { ActivityIndicator, StyleSheet } from "react-native";
import { StatusBar } from "expo-status-bar";

import { Layout, Text, TextProps } from "@ui-kitten/components";

import AnimateNumber from "react-native-animate-number";

import useStats from "./hooks/useStats";
import { useCurrentPodcast } from "../../../../../stores/currentPodcast";
import { FC } from "react";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    padding: "4vw",
  },
});

const Counter: FC<
  {
    value: number;
    label: string;
    plural?: string;
    zero?: string;
  } & TextProps
> = ({ value, label, plural = `${label}s`, zero = plural, ...props }) => (
  <Text {...props}>
    <AnimateNumber
      value={value}
      formatter={(val: number) =>
        val > 1000 ? `${val.toFixed(2)}k` : Math.floor(val).toString()
      }
    />
    {` ${value > 1 ? plural : value > 0 ? label : zero}`}
  </Text>
);

const LastEp = () => {
  const podcast = useCurrentPodcast();

  const { isLoading, isError, data } = useStats(podcast.id).lastep;

  if (isError) {
    console.error("Error occured while fetching last episode stats", data);
    return null;
  }

  const Title = <Text category="h3">Votre dernier épisode</Text>;

  if (isLoading) {
    return (
      <>
        {Title}
        <ActivityIndicator />
      </>
    );
  }

  if (!data) {
    return null;
  }

  return (
    <>
      {Title}
      <Text category="h4">{data.short_title}</Text>
      <Text category="h5">{data.published_at.toLocaleDateString()}</Text>
      <Counter value={data.downloads} label="téléchargement" />
    </>
  );
};

const Last90Days = () => {
  const podcast = useCurrentPodcast();

  const { isLoading, isError, data } = useStats(podcast.id).last90days;

  const Title = <Text category="h3">90 jours</Text>;

  if (isLoading) {
    return (
      <>
        {Title}
        <ActivityIndicator />
      </>
    );
  }

  if (!data) return null;

  return (
    <>
      {Title}
      <Counter category="h4" value={data.listeners} label="auditeur-ice" />
      <Counter category="h4" value={data.downloads} label="écoutes" />
    </>
  );
};

export default () => {
  const podcast = useCurrentPodcast();

  return (
    <Layout level="2" style={styles.container}>
      <StatusBar style="auto" />
      <LastEp />
      <Last90Days />
    </Layout>
  );
};
