import { useQuery } from "react-query";

import axios from "../../../../../../utils/axios";
import { PodcastID } from "../../../../../../stores/types/podcast";

const useUserPodcasts = (query?: string) =>
  useQuery<PodcastID[], { response: { data: { error: string } } }>(
    ["podcasts", query],
    () =>
      axios
        .get("/studio/podcasts", {
          params: {
            q: query,
          },
        })
        .then((res) => res.data),
    { keepPreviousData: true }
  );

export default useUserPodcasts;
