import { memo, useState } from "react";

import { StyleSheet } from "react-native";

import {
  Button,
  Divider,
  Icon,
  Layout,
  Popover,
  Text,
  useTheme,
} from "@ui-kitten/components";

import { useRecoilValue } from "recoil";

import Cover from "../../../../../../atoms/Cover";
import TransText from "../../../../../../atoms/TransText";

import {
  usePodcastsCountState,
  useRecentPodcastsState,
} from "../../../../../../stores/user";
import currentPodcastState from "../../../../../../stores/currentPodcast";
import { addTranslations } from "../../../../../../utils/i18n";
import { useNavigation } from "@react-navigation/native";
import { useShowOmnibar } from "../../../../../../stores/omnibar";

const styles = StyleSheet.create({
  podcastSwitcher: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    maxWidth: 250,
    maxHeight: 50,
    padding: 10,
  },
  title: {
    marginLeft: 12,
    marginRight: 10,
    textShadowColor: "rgba(0, 0, 0, 0.75)",
    textShadowOffset: { width: -1, height: 1 },
    textShadowRadius: 10,
    flexGrow: 1,
  },
  cover: {
    width: 32,
    height: 32,
  },
  backdrop: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  },
  switcher: {
    padding: 10,
  },
  switcherItem: {
    justifyContent: "flex-start",
    minHeight: 50,
  },
});

const useTranslation = addTranslations("Screens", {
  and_x_other_podcasts: {
    en: "and {{podcastsCount}} other podcasts...",
    fr: "et {{podcastsCount}} autres podcasts...",
  },
});

const PodcastSwitcher = memo(() => {
  const navigation = useNavigation();

  const podcast = useRecoilValue(currentPodcastState);

  const [switcherVisible, setSwitcherVisible] = useState(false);
  const showOmnibar = useShowOmnibar();

  const recentPodcasts = useRecentPodcastsState();
  const podcastsCount = usePodcastsCountState();

  const theme = useTheme();

  const { t } = useTranslation();

  if (!recentPodcasts) {
    return <Text>{t("Dashboard")}</Text>;
  }

  const otherPodcasts = podcastsCount - recentPodcasts.length;

  return (
    <Popover
      visible={switcherVisible}
      fullWidth={true}
      placement="bottom start"
      anchor={() => (
        <Button
          style={styles.podcastSwitcher}
          appearance="ghost"
          size="small"
          status="control"
          accessoryLeft={(props) =>
            podcast ? (
              <Cover
                source={podcast.cover.small}
                {...props}
                style={styles.cover}
              />
            ) : (
              <Icon name="grid-outline" {...props} />
            )
          }
          accessoryRight={(props) => <Icon name="swap" {...props} />}
          onPress={() => setSwitcherVisible(true)}
        >
          {(evaProps) =>
            podcast ? (
              <>
                <Text
                  {...evaProps}
                  category="s2"
                  style={styles.title}
                  numberOfLines={2}
                >
                  {podcast.title}
                </Text>
              </>
            ) : (
              <>
                <TransText
                  {...evaProps}
                  category="s2"
                  style={styles.title}
                  numberOfLines={2}
                >
                  Screens:Dashboard
                </TransText>
              </>
            )
          }
        </Button>
      )}
      onBackdropPress={() => setSwitcherVisible(false)}
    >
      <Layout style={styles.switcher} level="3">
        <Button
          style={styles.switcherItem}
          accessoryLeft={(props) => (
            <Icon
              name="grid-outline"
              {...props}
              fill={theme["color-primary-200"]}
            />
          )}
          appearance="ghost"
          size="small"
          onPress={() => {
            navigation.navigate("Dashboard");
            setSwitcherVisible(false);
          }}
        >
          {(evaProps) => (
            <TransText
              {...evaProps}
              style={[evaProps?.style, { color: theme["color-primary-200"] }]}
            >
              Screens:Dashboard
            </TransText>
          )}
        </Button>
        <Divider />
        {recentPodcasts.map((p) => (
          <Button
            style={styles.switcherItem}
            size="small"
            appearance="ghost"
            accessoryLeft={(props) => (
              <Cover source={p.cover.small} style={styles.cover} />
            )}
            onPress={() => {
              navigation.navigate("Podcast", { podcast_id: p.id });
              setSwitcherVisible(false);
            }}
            key={`p_switch_${p.id}`}
          >
            {(evaProps) => (
              <Text
                {...evaProps}
                style={[evaProps?.style, { color: theme["color-primary-200"] }]}
              >
                {p.title}
              </Text>
            )}
          </Button>
        ))}
        {otherPodcasts > 0 ? (
          <Button
            style={styles.switcherItem}
            size="small"
            appearance="ghost"
            accessoryLeft={(props) => (
              <Icon
                name="search"
                {...props}
                fill={theme["color-primary-200"]}
              />
            )}
            onPress={() => {
              setSwitcherVisible(false);
              showOmnibar();
            }}
            key={`p_switch_${podcastsCount}_others`}
          >
            {(evaProps) => (
              <Text
                {...evaProps}
                style={[evaProps?.style, { color: theme["color-primary-200"] }]}
                category="s2"
              >
                {
                  t("and_x_other_podcasts", {
                    podcastsCount: otherPodcasts,
                  }) as string
                }
              </Text>
            )}
          </Button>
        ) : null}
      </Layout>
    </Popover>
  );
});

export default PodcastSwitcher;
