import axios from "../../../../utils/axios";
import { useMutation } from "react-query";

export type EmailCheckResult = {
  email: string;
  errors: string[];
  tooManyOTPs: boolean;
  hasPassword: boolean;
  isSignUp: boolean;
  languages: string[];
};

const useEmailChecker = () =>
  useMutation<
    EmailCheckResult,
    { response: { data: EmailCheckResult } },
    { email: string; lang: string }
  >((data) =>
    axios
      .get("/users/email_type", {
        params: data,
      })
      .then((res) => res.data)
  );

export default useEmailChecker;
