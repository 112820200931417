import * as React from "react";

import {
  Button,
  Text,
  Icon,
  IconProps,
  TextProps,
  ButtonProps,
} from "@ui-kitten/components";

import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  step: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    margin: 10,
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 10,
  },
  stepNumber: {
    margin: 10,
    marginRight: 15,
  },
  stepDisabled: {
    opacity: 0.3,
  },
  stepText: {
    flexGrow: 1,
    fontSize: 15,
  },
});

type StepProps = {
  number: number | string;
  children?: React.ReactNode[];
} & ButtonProps;

const Step = ({ number, children, disabled, ...props }: StepProps) => (
  <Button
    style={[styles.step, disabled && styles.stepDisabled]}
    size="giant"
    accessoryRight={(props: IconProps) => (
      <Icon name="chevron-right-outline" {...props} />
    )}
    disabled={!!disabled}
    {...props}
  >
    {(evaProps: TextProps) => (
      <>
        <Text {...evaProps} category="h2" style={styles.stepNumber}>
          {number}
        </Text>
        <Text {...evaProps} style={[evaProps.style, styles.stepText]}>
          {children}
        </Text>
      </>
    )}
  </Button>
);

export default Step;
