import { useLoggedInState, useRegistrationState } from "../stores/user";

import CompleteRegistration from "./organisms/CompleteRegistration";

import UserLoader from "./atoms/UserLoader";

import Login from "./organisms/Login";
import LoggedIn from "./organisms/LoggedIn";

const GateKeeper = () => {
  const isLoggedIn = useLoggedInState();

  const { shouldCompleteRegistration } = useRegistrationState();

  console.log("GateKeeper", { shouldCompleteRegistration, isLoggedIn });

  return (
    <UserLoader>
      {shouldCompleteRegistration ? (
        <CompleteRegistration />
      ) : isLoggedIn ? (
        <LoggedIn />
      ) : (
        <Login />
      )}
    </UserLoader>
  );
};

export default GateKeeper;
