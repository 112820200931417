import { AxiosError } from "axios";
import { useQuery } from "react-query";

import { Platform } from "react-native";

import axios, { setToken } from "../../../utils/axios";

import { useSetUserState } from "../../../stores/user";
import User from "../../../stores/types/user";

type SSORequestError = AxiosError<{ errors: string[] }>;

const isNotSignedIn = (err: SSORequestError) =>
  err?.response?.data?.errors?.[0] === "not_signed_in";

const useUserLoader = () => {
  const setUser = useSetUserState();
  const useSSO = Platform.OS == "web";

  const userLoader = useQuery<User, SSORequestError>(
    "User",
    () =>
      axios
        .get("/users/me", {
          SSO: useSSO,
        })
        .then((res) => res.data),
    {
      onError: (err) => {
        if (isNotSignedIn(err)) {
          setUser(null);
          setToken(null);
        } else {
          console.error(err);
        }
      },
      onSuccess: (data) => {
        setUser(data);
        setToken(data?.jwt || null);
      },
      retry: (_failureCount, err) => {
        if (isNotSignedIn(err)) {
          return false;
        }

        return true;
      },
    }
  );

  return userLoader;
};

export default useUserLoader;
