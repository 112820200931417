import { useSetRecoilState } from "recoil";
import { useQuery } from "react-query";

import { AxiosResponse } from "axios";

import axios from "../../../../utils/axios";

import { PodcastID } from "../../../../stores/types/podcast";

import Podcast from "../../../../stores/types/podcast";
import currentPodcastState from "../../../../stores/currentPodcast";

const usePodcast = (podcastID: PodcastID["id"]) => {
  const setPodcast = useSetRecoilState(currentPodcastState);

  return useQuery<Podcast, { response: { data: { error: string } } }>(
    ["podcasts", podcastID],
    () =>
      axios
        .get("/studio/podcasts/" + encodeURIComponent(podcastID), {
          params: {},
        })
        .then((res: AxiosResponse<Podcast>) => res.data),
    {
      keepPreviousData: true,
      onSuccess: (data) => setPodcast(data),
    }
  );
};
export default usePodcast;
