import { API_BASE_URL } from "@env";

import axios from "axios";
import { parseISO } from "date-fns";

const client = axios.create({ baseURL: API_BASE_URL });

const isoDateFormat =
  /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)(([-+](\d{2}):(\d{2})|Z)?)$/;

function isIsoDateString(value: any): boolean {
  return value && typeof value === "string" && isoDateFormat.test(value);
}

function handleDates(body: any) {
  if (body === null || body === undefined || typeof body !== "object")
    return body;

  for (const key of Object.keys(body)) {
    const value = body[key];
    if (isIsoDateString(value)) body[key] = parseISO(value);
    else if (typeof value === "object") handleDates(value);
  }
}

client.interceptors.response.use((originalResponse) => {
  handleDates(originalResponse.data);
  return originalResponse;
});

const tokenStore: { token: string | null } = {
  token: null,
};

export const setToken = (token: string | null) => (tokenStore.token = token);

declare module "axios" {
  interface AxiosRequestConfig {
    SSO?: boolean;
  }
}

client.interceptors.request.use(function (config) {
  if (config.SSO) {
    // Ignore token, use credentials
    delete config.SSO;
    config.headers.Authorization = "SSO";
    config.withCredentials = true;
  } else {
    const token = tokenStore.token;

    if (token) {
      config.headers.Authorization =
        config.headers.Authorization || `Token ${token}`;
    }
  }

  return config;
});

export default client;
