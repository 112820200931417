import { StyleSheet } from "react-native";

import { Text } from "@ui-kitten/components";

const styles = StyleSheet.create({
  em: {
    fontStyle: "italic",
  },
});

const Em = (props) => (
  <Text { ...props} style={[styles.em, props.style]} />
);

export default Em;