import { StyleSheet } from "react-native";

export default StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    padding: "5vw",
  },
  welcomeTitle: {
    textAlign: "center",
  },
  welcomeText: {
    marginTop: 40,
    marginBottom: 20,
    maxWidth: "70vw",
    textAlign: "center",
  },
  welcomeS2: {
    marginVertical: 20,
  },
  podcastAlreadyExists: {
    textAlign: "right",
    textDecorationLine: "underline",
    marginVertical: 10,
    marginTop: 5,
    marginRight: 20,
  },
});
