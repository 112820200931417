import { createStackNavigator } from "@react-navigation/stack";

import { useWindowDimensions } from "react-native";
import { useResetRecoilState } from "recoil";

import Header from "./molecules/Header";

import Dashboard from "../../screens/Dashboard";
import Podcast from "../../screens/Podcast";

import podcastState from "../../../stores/currentPodcast";
import Omnibar from "./molecules/Omnibar";

const { Navigator, Screen } = createStackNavigator();

const LoggedIn = () => {
  const resetPodcast = useResetRecoilState(podcastState);

  const dimensions = useWindowDimensions();

  const isLargeScreen = dimensions.width >= 768;

  return (
    <>
      <Omnibar />
      <Header isLargeScreen={isLargeScreen} />
      <Navigator
        screenOptions={{
          header: (props) => null,
          animationEnabled: true,
          animationTypeForReplace: "pop",
        }}
      >
        <Screen
          name="Dashboard"
          component={Dashboard}
          listeners={{
            focus: (e) => {
              resetPodcast();
            },
          }}
        />
        <Screen name="Podcast" component={Podcast} />
      </Navigator>
    </>
  );
};

export default LoggedIn;
