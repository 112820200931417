import { StyleSheet } from "react-native";

import { Divider, Layout } from "@ui-kitten/components";

import PodcastSwitcher from "./PodcastSwitcher";

import PodcastSubNav from "./PodcastSubNav";
import currentPodcastState from "../../../../../../stores/currentPodcast";
import { useRecoilValue } from "recoil";

const styles = StyleSheet.create({
  header: {
    flex: 1,
    flexDirection: "column",
    maxHeight: 60,
  },
  headerWithSubNav: {
    maxHeight: 86,
  },
  headerBar: {
    flex: 1,
    flexDirection: "row",
    maxHeight: 50,
  },
});

const LargeScreenHeader = () => {
  const podcast = useRecoilValue(currentPodcastState);

  return (
    <Layout style={[styles.header, podcast && styles.headerWithSubNav]}>
      <Layout style={styles.headerBar}>
        <PodcastSwitcher />
      </Layout>
      <Divider />
      {podcast && <PodcastSubNav />}
    </Layout>
  );
};

export default LargeScreenHeader;
