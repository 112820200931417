import { useEffect, useState } from "react";

import {
  Button,
  Card,
  Icon,
  Input,
  Layout,
  Text,
  useTheme,
} from "@ui-kitten/components";

import { ActivityIndicator, StyleSheet, View } from "react-native";

import useTranslation from "./ClaimUsername.i18n";

import Example from "./Example";
import useUsernameClaim from "./hooks/useUsernameClaim";
import useDebounce from "../../../../../hooks/useDebounce";
import { useEmailState } from "../../../../../stores/user";
import ErrorDisplay from "../../../../atoms/ErrorDisplay";

const styles = StyleSheet.create({
  card: {
    maxWidth: 400,
    width: "90vw",
  },
  intro: {
    marginBottom: 20,
  },
  p: {
    marginBottom: 10,
  },
  username: { marginTop: 25, marginBottom: 15 },
  examples: {
    marginVertical: 10,
    padding: 20,
    paddingTop: 10,
  },
  examples_title: {
    marginBottom: 10,
  },
  examples_line: {
    flexDirection: "row",
  },
});

const cleanIdentifierFromEmail = (email: string) =>
  `${email.match("(.*)@")?.[1]}`
    .replace(/[^A-z0-9_]/g, "_")
    .replace(/_{2,}/g, "");

const ClaimUsername = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  // we're sure email is there because we're logged in
  const email = useEmailState() as string;

  const [username, setUsername] = useState<string>(
    cleanIdentifierFromEmail(email)
  );
  const debouncedUsername = useDebounce(username, 500);

  const claimUsername = useUsernameClaim();

  useEffect(() => {
    if (debouncedUsername.length > 0) {
      claimUsername.mutate({ username: debouncedUsername });
    } else {
      claimUsername.reset();
    }
  }, [debouncedUsername]);

  console.log({ theme });

  return (
    <Card
      style={[
        styles.card,
        { backgroundColor: theme["background-basic-color-3"] },
      ]}
      status="primary"
      appearance="filled"
      header={(props) => (
        <View {...props}>
          <Text category="h6">{t("one_moment")}</Text>
        </View>
      )}
      footer={(props) => (
        <View {...props}>
          <Button
            status="primary"
            onPress={() => claimUsername.mutate({ username, save: true })}
          >
            {t("complete_registration")}
          </Button>
        </View>
      )}
    >
      <Text style={styles.intro} category="p1">
        {t("before_access")}
      </Text>
      <Text style={styles.p} category="p2">
        {t("choose_your_username")}
      </Text>
      <Input
        label={t("username")}
        caption={t("username_chars")}
        style={styles.username}
        value={username}
        onChangeText={(text) => setUsername(text)}
        accessoryRight={
          claimUsername.isIdle ? undefined : claimUsername.isLoading ||
            username !== debouncedUsername ? (
            <ActivityIndicator />
          ) : (
            <Icon
              name={
                claimUsername.isError
                  ? "alert-circle-outline"
                  : "checkmark-circle-outline"
              }
              fill={
                theme[
                  claimUsername.isError
                    ? "color-danger-400"
                    : "color-success-400"
                ]
              }
              animation="pulse"
            />
          )
        }
        autoFocus
      />
      <ErrorDisplay query={claimUsername} style={{ marginTop: 0 }} showEmpty />
      <Text style={styles.p} category="p2">
        {t("not_your_podcast_name")}
      </Text>
      <Layout level="2" style={styles.examples}>
        <Text category="s1" style={styles.examples_title}>
          {t("some_examples.title")}
        </Text>
        <View style={styles.examples_line}>
          <Example bad>{t("some_examples.bad1")}</Example>
          <Example good>{t("some_examples.good1")}</Example>
        </View>
        <View style={styles.examples_line}>
          <Example bad>{t("some_examples.bad2")}</Example>
          <Example good>{t("some_examples.good2")}</Example>
        </View>
      </Layout>
    </Card>
  );
};

export default ClaimUsername;
