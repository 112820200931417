import { useTheme, Text } from "@ui-kitten/components";
import { DarkTheme } from "@react-navigation/native";

import { NavigationContainer } from "@react-navigation/native";

import { useTranslation } from "react-i18next";

import GateKeeper from "./GateKeeper";

const AppNavigation = () => {
  const theme = useTheme();

  const { t } = useTranslation();

  return (
    <NavigationContainer
      linking={{
        prefixes: [
          "https://studio.podcloud.fr",
          "https://studio.podcloud.test",
          "podcloud-studio://",
        ],
        config: {
          screens: {
            Dashboard: "dashboard",
            Podcast: {
              path: "podcast/:podcast_id",
              screens: {
                Summary: "/summary",
                Episodes: "/episodes",
                Stats: "/stats",
              },
            },
          },
        },
      }}
      fallback={<Text>Loading...</Text>}
      theme={{
        ...DarkTheme,
        colors: {
          ...DarkTheme.colors,
          background: theme["background-basic-color-1"],
        },
      }}
      documentTitle={{
        formatter: (options, route) => {
          const app = "podCloud Studio";
          const title = options?.title ?? t(`Screens:${route?.name}`);

          return title && title !== "undefined" ? `${title} - ${app}` : app;
        },
      }}
    >
      <GateKeeper />
    </NavigationContainer>
  );
};

export default AppNavigation;
