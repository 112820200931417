import { DrawerActions, useNavigation } from "@react-navigation/native";
import { Layout, Tab, TabBar, useTheme } from "@ui-kitten/components";

import { StyleSheet } from "react-native";

import useCurrentRoute from "../../../../../../hooks/useCurrentRoute";
import { usePodcastScreens } from "../../../../../screens/Podcast/screens";
import TransText from "../../../../../../atoms/TransText";

const styles = StyleSheet.create({
  tabs: {
    paddingHorizontal: 15,
    marginHorizontal: 10,
    maxWidth: 400,
    maxHeight: 40,
  },
});

const PodcastSubNav = () => {
  const navigation = useNavigation();

  const currentRoute = useCurrentRoute();

  const screens = usePodcastScreens(currentRoute?.path);

  const theme = useTheme();

  return (
    <>
      <Layout style={styles.tabs}>
        <TabBar
          selectedIndex={screens.selected?.row}
          onSelect={(index) =>
            navigation.dispatch(DrawerActions.jumpTo(screens[index].name))
          }
          indicatorStyle={{ backgroundColor: theme["color-primary-200"] }}
        >
          {screens.map((item) => (
            <Tab
              key={item.row}
              title={({ style, ...props }) => (
                <TransText
                  style={[
                    style,
                    { color: theme["color-primary-100"] },
                    item.selected && { color: theme["color-primary-200"] },
                  ]}
                  {...props}
                >
                  {`creens:${item.name}`}
                </TransText>
              )}
            />
          ))}
        </TabBar>
      </Layout>
    </>
  );
};

export default PodcastSubNav;
